'use strict';

var angularTestPassed = false;

// Test local storage
var localstorageWriteable = false;
if(!!window.localStorage) {
    try {
        window.localStorage.setItem("test", "test");
        window.localStorage.removeItem("test");
        localstorageWriteable = true;
    } catch(e) {
    }
}
if(localstorageWriteable) {
    $("#rwlocalstorage").addClass("rwlocalstorage");
} else {
    $("#no-rwlocalstorage").addClass("no-rwlocalstorage");
}

// Test session storage
var sessionstorageWriteable = false;
if(!!window.sessionStorage) {
    try {
        window.sessionStorage.setItem("test", "test");
        window.sessionStorage.removeItem("test");
        sessionstorageWriteable = true;
    } catch(e) {
    }
}
if(sessionstorageWriteable) {
    $("#rwsessionstorage").addClass("rwsessionstorage");
} else {
    $("#no-rwsessionstorage").addClass("no-rwsessionstorage");
}

var compatability = angular.module('compatability', []);
compatability.controller('CompatabilityCtrl', ['$scope', '$http',
    function($scope, $http) {
        $http({
            method: 'GET',
            url: '/version'
        }).then(function success(res) {
            $scope.angularTestPassed = res && res.data && res.data.name == 'licensing';
            angularTestPassed = $scope.angularTestPassed;
        }, function error(res) {
            console.error(res);
        });
    }
]);

$(document).ready(function() {
    $('#compatabilityFormSubmitted').hide();
    var form = $('#compatabilityForm');
    form.submit(function(ev) {
        ev.preventDefault();
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            dataType: 'json',
            data: {
                name: $('input[name="name"]').val(),
                ciprid:  $('input[name="ciprid"]').val(),
                contact: $('input[name="contact"]').val(),
                cookies: Modernizr.cookies,
                cors: Modernizr.cors,
                notie8compat: !Modernizr.ie8compat,
                localstorage: Modernizr.localstorage,
                localstorageWriteable: localstorageWriteable,
                sessionstorage: Modernizr.sessionstorage,
                sessionstorageWriteable: sessionstorageWriteable,
                json: Modernizr.json,
                es5: Modernizr.es5,
                angular: angularTestPassed,
                xhrresponsetype: Modernizr.xhrresponsetype,
                xhrresponsetypeblob: Modernizr.xhrresponsetypeblob,
                xhrresponsetypedocument: Modernizr.xhrresponsetypedocument,
                xhrresponsetypejson: Modernizr.xhrresponsetypejson,
                xhrresponsetypetext: Modernizr.xhrresponsetypetext,
                websockets: Modernizr.websockets,
                websocketsbinary: Modernizr.websocketsbinary,
                formvalidation: Modernizr.formvalidation,
                fileinput: Modernizr.fileinput,
                adownload: Modernizr.adownload,
                atobbtoa: Modernizr.atobbtoa,
                unicode: Modernizr.unicode,
                fontface: Modernizr.fontface,
                history: Modernizr.history,
                canvas: Modernizr.canvas,
                queryselector: Modernizr.queryselector
            }
        });
        $('#compatabilityPortlet').hide();
        $('#compatabilityFormSubmitted').show();
    });
});
